/* Viewportsize */

#viewportsize {
    @apply text-red-400 text-xs font-bold fixed left-0 p-2 pl-12 bottom-0 rounded-tr bg-black z-50;
}
#viewportsize::before {
    @apply text-white w-9 ml-1 mb-1.5 text-right bottom-2  font-bold;
}
#viewportsize {
    display: none;
}